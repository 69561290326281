
.react-datepicker {
  border: 1px solid #e4e4e4;
}

.react-datepicker__triangle {
  display: none;
}
.react-datepicker__header {
  background-color: #FAF9F8;
  border-bottom: none;
}

.react-datepicker__month {
  background-color: #FAF9F8;
  margin: 0;
  padding: 0.4rem;
}
.react-datepicker__day--keyboard-selected, .react-datepicker__day--selected, .react-datepicker__day--in-range {
  background-color: #867666;
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range, .react-datepicker__day:hover) {
  background-color: #8676666b;
}
.react-datepicker__day:hover {
  background-color: #867666;
  color: #FFFFFF;
}
.react-datepicker__day--keyboard-selected:hover, .react-datepicker__day--selected:hover, .react-datepicker__day--in-range:hover {
  background-color: #867666;
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range) {
  background-color: #8676666b;
  color: #FFFFFF;
}

